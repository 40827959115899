import "./App.css";
import OpenAI from "openai";
import React, { useState, useEffect } from "react";
import { Jelly } from "@uiball/loaders";

const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true,
});

const { REACT_APP_PROMPT_1, REACT_APP_PROMPT_2 } = process.env;

const Typewriter = ({ text, speed }) => {
  const [displayText, setDisplayText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (currentIndex < text.length) {
        setDisplayText(text.slice(0, currentIndex + 1));
        setCurrentIndex(currentIndex + 1);
      }
    }, speed);

    return () => {
      clearTimeout(timer);
    };
  }, [text, currentIndex, speed]);

  return <div>{displayText}</div>;
};

function App() {
  const [title, displayTitle] = useState("howistheweather.today");
  const [text, setText] = useState("");
  const [location, setLocation] = useState("");
  const [loading, setLoading] = useState(false);

  const runAi = async (location) => {
    try {
      setLoading(true);
      displayTitle(false);
      const completion = await openai.chat.completions.create({
        messages: [
          {
            role: "system",
            content: `${REACT_APP_PROMPT_1}${location}${REACT_APP_PROMPT_2}`,
          },
        ],
        model: "gpt-3.5-turbo",
      });
      await new Promise((resolve) => setTimeout(resolve, 1000));
      const res = completion.choices[0].message.content;
      setText(res);
    } catch (error) {
      console.error("An error occurred:", error);
      setText("Error fetching weather information. Try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setText("");
    runAi(location);
  };

  return (
    <div
      className="app-container"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        flexDirection: "column",
        marginLeft: "center",
        marginRight: "center",
      }}
    >
      <div>{title}</div>
      {loading ? (
        <Jelly size={80} speed={0.9} color="black" />
      ) : (
        <Typewriter text={text} speed={50} />
      )}
      <br />
      <form className="form" onSubmit={handleSearch}>
        <input
          type="text"
          id="location"
          className="form-input"
          placeholder="where?"
          style={{ marginRight: "5px" }}
          value={location}
          onChange={(e) => {
            setLocation(e.target.value);
          }}
        />
        <button type="submit">Search</button>
      </form>
    </div>
  );
}

export default App;
